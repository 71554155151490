import React from 'react';
import Auth from "@aws-amplify/auth";

async function onLogoutfn() {
    // console.log('got to onLogoutfn')
    try {
        await Auth.signOut();
        window.location.replace('/')
    } catch (error) {
        console.log('error signing out: ', error);
    }
}
function Header() {
  return (
    <header className="text-gray-600 body-font">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
            <a className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0" href='./' alt='home'>
                <img className='dwlogo' src='https://s3.eu-west-2.amazonaws.com/executivefunctionskills.co.uk/images/bookcovers/EFS+Logo+2.png' alt='site logo' />
                <span className="ml-3 text-xl"></span>
            </a>
            <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
                <a href='./' className="mr-5 nav">Home</a>
                <a className="mr-5 hover:text-gray-900" href='./About'>About</a>
            </nav>
            <button type="submit" onClick={onLogoutfn}
                     className="inline-flex items-center bg-gray-400 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0">
                Logout
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
            </button>
        </div>
    </header>
  )
}

export default Header;
