import React from 'react';
const tagManagerArgs = {
    dataLayer: {
        userId: '001',
        userProject: 'ExecutiveFunctionSkills',
        page: 'downloads.js'
    },
    dataLayerName: 'PageDataLayer'
}
function Downloads() {

    return (
      <section className="text-gray-600 body-font">
          <div className="container mx-auto flex flex-col px-5 py-24 justify-center items-center">
              <div className="w-full md:w-2/3 flex flex-col mb-16 items-center text-center">
                  <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Click any of the PDF's to Download</h1>
              </div>

              <div className="flex flex-wrap -m-5">
                  {/* div className="p-4 xl:w-1/4 md:w-1/2 w-full">
                      <div className="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
                          <a target="_blank" alt="4-Step Action Plan"
                             className="lg:w-5/6 md:w-5/6 w-5/6 mb-10 object-cover object-center rounded"
                             href="https://s3.eu-west-2.amazonaws.com/executivefunctionskills.co.uk/Executive+Functioning+For+Teens+And+Parents+Review+Copy+xii.pdf">
                             <img className="dwlook lg:w-5/6 md:w-5/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero"
                             src="https://s3.eu-west-2.amazonaws.com/executivefunctionskills.co.uk/images/clipart2022616.png"/>
                          </a>
                          <h2 className="text-sm tracking-widest title-font mb-1 font-medium">Dowload the book <br/>for review</h2>
                          <h1 className="text-5xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none">Free</h1>
                      </div>
                  </div>*/}
                  <div className="p-4 xl:w-1/4 md:w-1/2 w-full">
                      <div className="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
                          <a target="_blank" rel="noreferrer" alt="4-Step Action Plan"
                             className="lg:w-5/6 md:w-5/6 w-5/6 mb-10 object-cover object-center rounded"
                             href="https://s3.eu-west-2.amazonaws.com/executivefunctionskills.co.uk/images/pdfs/book/4+Step+Action+Plan.pdf">
                             <img className="dwlook lg:w-5/6 md:w-5/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero"
                             src="https://s3.eu-west-2.amazonaws.com/executivefunctionskills.co.uk/images/clipart2022616.png"/>
                          </a>
                          <h2 className="text-sm tracking-widest title-font mb-1 font-medium">4-Step Action Plan <br/>Worksheet</h2>
                          <h1 className="text-5xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none">Free</h1>
                      </div>
                  </div>
                  <div className="p-4 xl:w-1/4 md:w-1/2 w-full">
                      <div className="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
                          <a target="_blank" rel="noreferrer" alt="4-Step Exercise Matrix "
                             className="lg:w-5/6 md:w-5/6 w-5/6 mb-10 object-cover object-center rounded"
                             href="https://s3.eu-west-2.amazonaws.com/executivefunctionskills.co.uk/images/pdfs/4+Step+Exercise+Matrix.pdf">
                             <img className="dwlook lg:w-5/6 md:w-5/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero"
                             src="https://s3.eu-west-2.amazonaws.com/executivefunctionskills.co.uk/images/clipart2022616.png"/>
                          </a>
                          <h2 className="text-sm tracking-widest title-font mb-1 font-medium">4-Step Exercise Matrix <br/>Worksheet</h2>
                          <h1 className="text-5xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none">Free</h1>
                      </div>
                  </div>
                  <div className="p-4 xl:w-1/4 md:w-1/2 w-full">
                      <div className="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
                          <a target="_blank" rel="noreferrer" alt="Misbehavior Management Worksheet"
                             className="lg:w-5/6 md:w-5/6 w-5/6 mb-10 object-cover object-center rounded"
                             href="https://s3.eu-west-2.amazonaws.com/executivefunctionskills.co.uk/images/pdfs/Misbehavior+Management+Worksheet.pdf">
                             <img className="dwlook lg:w-5/6 md:w-5/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero"
                             src="https://s3.eu-west-2.amazonaws.com/executivefunctionskills.co.uk/images/clipart2022616.png"/>
                          </a>                        <h2 className="text-sm tracking-widest title-font mb-1 font-medium">Misbehavior Management Worksheet</h2>
                          <h1 className="text-5xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none">Free</h1>
                      </div>
                  </div>
                  <div className="p-4 xl:w-1/4 md:w-1/2 w-full">
                      <div className="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
                          <a target="_blank" rel="noreferrer" alt="Positive Reinforcement Worksheet"
                             className="lg:w-5/6 md:w-5/6 w-5/6 mb-10 object-cover object-center rounded"
                             href="https://s3.eu-west-2.amazonaws.com/executivefunctionskills.co.uk/images/pdfs/Positive+Reinforcement+Worksheet.pdf">
                             <img className="dwlook lg:w-5/6 md:w-5/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero"
                             src="https://s3.eu-west-2.amazonaws.com/executivefunctionskills.co.uk/images/clipart2022616.png"/>
                          </a>                        <h2 className="text-sm tracking-widest title-font mb-1 font-medium">Positive Reinforcement Worksheet</h2>
                          <h1 className="text-5xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none">Free</h1>
                      </div>
                  </div>
                  <div className="p-4 xl:w-1/4 md:w-1/2 w-full">
                      <div className="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
                          <a target="_blank" rel="noreferrer" alt="Self Regulatory Skills Clusters Poster"
                             className="lg:w-5/6 md:w-5/6 w-5/6 mb-10 object-cover object-center rounded"
                             href="https://s3.eu-west-2.amazonaws.com/executivefunctionskills.co.uk/images/pdfs/Self+Regulatory+Executive+Functioning+Skills+Clusters.pdf">
                             <img className="dwlook lg:w-5/6 md:w-5/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero"
                             src="https://s3.eu-west-2.amazonaws.com/executivefunctionskills.co.uk/images/clipart2022616.png"/>
                          </a>
                          <h2 className="text-sm tracking-widest title-font mb-1 font-medium">Self Regulatory Skills Clusters Poster</h2>
                          <h1 className="text-5xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none">Free</h1>
                      </div>
                  </div>
                  <div className="p-4 xl:w-1/4 md:w-1/2 w-full">
                      <div className="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
                          <a alt="Visit Again Soon, there will be more"
                            className="lg:w-5/6 md:w-5/6 w-5/6 mb-10 object-cover object-center rounded"
                            href="/">
                              <img className="dwlook lg:w-5/6 md:w-5/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero"
                              src="https://s3.eu-west-2.amazonaws.com/executivefunctionskills.co.uk/images/clipart2022616.png"/>
                          </a>
                          <h2 className="text-sm tracking-widest title-font mb-1 font-medium">Visit Again Soon, there will be more</h2>
                          <h1 className="text-5xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none">Free</h1>
                      </div>
                  </div>
              </div>
          </div>
      </section>


    )
}

export default Downloads;
