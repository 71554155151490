import React from 'react';
import TagManager from 'react-gtm-module';
// import '../App2.css';
const tagManagerArgs = {
    dataLayer: {
        userId: '001',
        userProject: 'ExecutiveFunctionSkills',
        page: 'privacy.js'
    },
    dataLayerName: 'PageDataLayer'
}

function WelcomeNews() {
  TagManager.dataLayer(tagManagerArgs);

  return (
    <section className="text-gray-600 body-font">
        <div className="container mx-auto px-5 py-24 items-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Privacy Policy</h1>
          <p>This privacy notice tells you about the information we collect from you when you use our website. In collecting this information,
          we are acting as a data controller and, by law, we are required to provide you with information about us, about why and how we use
          your data, and about the rights you have over your data.</p>
          <h3>Who are we?</h3>
          <p>We are Aldwych Factors Ltd. Our address is Office 7, 35-37 Ludgate Hill, London, England, EC4M 7JN, United Kingdom. You can contact
          us by post at the above address, by email at <a href="mailto:support@esorma.com"><strong>support@esorma.com</strong></a>.</p>
          <p>We are not required to have a data protection officer, so any enquiries about our use of your personal data should be addressed
          to the contact details above.</p>
          <p>&nbsp;</p>
          <h3>How we use your information</h3>
          <p>
              <a href="#when-you-use-our-website"><strong>When you use our website</strong></a><br />
              <a href="#submit-enquiry-through-website">
                  <strong>
                      When you submit an enquiry via our website<br />
                  </strong>
              </a><a href="#purchase-product"><strong>When you purchase a product from our website</strong></a><br />
              <a href="#sign-up-newsletter">
                  <strong>
                      When you sign up to receive our newsletter<br />
                  </strong>
              </a><a href="#download-sample">
                  <strong>
                      When you download a sample document<br />
                  </strong>
              </a><a href="#feedback-survey">
                  <strong>
                      When you take part in our feedback survey<br />
                  </strong>
              </a><a href="#request-more-information">
                  When you request more information about our third party products and services
              </a><br />
              <a href="#rights-data-subject"><strong>Your rights as a data subject</strong></a><br />
              <a href="#right-to-complain"><strong>Your right to complain</strong></a><br />
              <a href="#updates-to-this-policy"><strong>Updates to this privacy policy</strong></a>
          </p>
          <h3 id="when-you-use-our-website">When you use our website</h3>
          <p>When you use our website to browse our products and services and view the information we make available, a number of cookies
          are used by us and by third parties to allow the website to function, to collect useful information about visitors and to help to
          make your user experience better.</p>
          <p>For more information about our use of cookies, please see our <a href="https://esorma.com/cookie-policy/">
          <strong>cookie policy</strong></a>.</p>
          <h3 id="submit-enquiry-through-website"><strong>When you submit an enquiry via our website</strong></h3>
          <p>When you submit an enquiry via our website, we ask you for your name, contact telephone number and email address.</p>
          <p>We use this information to respond to your query, including providing you with any requested information about our products and
          services. We may also email you several times after your enquiry in order to follow up on your interest and ensure that we have
          answered your enquiry to your satisfaction. We will do this based on our legitimate interest in providing accurate information
          prior to a sale.</p>
          <p>Your enquiry is stored and processed as an email which is hosted by <a href="https://www.mailjet.com/security-privacy/">
          <strong>Mailjet</strong></a> within the European Economic Area (EEA).</p>
          <p>We do not use the information you provide to make any automated decisions that might affect you.</p>
          <p>We keep enquiry emails for two years, after which they are securely archived and kept for seven years, when we delete them.</p>
          <h3 id="purchase-product">When you purchase a product from our website</h3>
          <p>When you purchase products from us on line, we ask you for your name, address, contact telephone number, email address and
          credit card information. We also record your IP (Internet Protocol) address, which is the address of your computer on the Internet.</p>
          <p>We will use your information to verify your credit card details for your purchase, process your order and to send you your
          products. We will also send you a receipt via email and we may use your telephone number to contact you regarding your purchase.</p>
          <p>We require this information in order to process your payment, deliver your products or services and fulfill our contract with
          you. We record your IP address in order to show that the correct tax was applied to the sale, which we are required to do by law.</p>
          <p>Your information is stored on our website, which is hosted with <a href="https://aws.amazon.com/privacy/">
          <strong>Amazon Web Services</strong></a> based within the United Kingdom. Your credit card details are passed to a third-party
          payment processor called <a href="https://www.chargebee.com/privacy/"><strong>Chargebee</strong></a> which is based in the USA
          and is certified to the <a href="https://www.privacyshield.gov/welcome"><strong>EU-US Privacy Shield</strong></a> (which requires
            effective safeguards for your information). We do not retain your credit card information.</p>
          <p>We do not use the information you provide to make any automated decisions that might affect you.</p>
          <p>We keep your order information for an indefinite period, as the licenses we sell for our products do not expire.</p>
          <h3 id="sign-up-newsletter">When you sign up to receive our newsletter</h3>

          <p>When you sign up to receive our newsletter, we ask for your name and your email address.</p>
          <p>We will ask for your consent to use your name and email address to email you our newsletter which contains information about
          our products and other information which we feel might be of interest to you.</p>
          <p>You can withdraw your consent at any time and we will stop sending you the newsletter.</p>
          <p>Your name and email address are stored on our website which is hosted with <a href="https://aws.amazon.com/privacy/">
          <strong>Amazon Web Services</strong></a> based within the United Kingdom.</p>
          <p>We do not use the information you provide to make any automated decisions that might affect you.</p>
          <p>We keep your personal data for as long as we produce and distribute our newsletter. If you withdraw your consent, we will mark
          your details so that they are not used and delete them after two years.</p>
          <h3 id="download-sample">When you download a sample document</h3>
          <p>When you request to download a sample document from one of our toolkits, we ask for your name, company name (which is optional)
           and your email address.</p>
          <p>We use this information to email you a link to download the requested document. We may also email you after your download in
          order to follow up on your interest in our products and services. We will do this based on our legitimate interest in marketing
          to prospects for our products and services.</p>
          <p>Your name and email address are stored on our website which is hosted with <a href="https://aws.amazon.com/privacy/">
          <strong>Amazon Web Services</strong></a> based within the United Kingdom.</p>
          <p>We do not use the information you provide to make any automated decisions that might affect you.</p>
          <p>Your personal data is stored for one year after you requested your download, after which it is deleted.</p>
          <h3 id="feedback-survey">When you take part in our feedback survey</h3>
          <p>When you take part in our feedback survey, we record your name, your email address, your organisation name, your job role
          and your answers to questions we ask about your opinion of, and use of, our products and services.</p>
          <p>We use this information to develop and improve our products and services and our customer relationships. Your job role,
          organisation and some or all of your comments may be publicly displayed on our website as a testimonial. We will do this based
          on our legitimate interest in marketing our products and services.</p>
          <p>Your details are shared with a third party surveys system, hosted by <a href="https://policies.google.com/privacy?hl=en">
          <strong>Google</strong></a>, which is based within the European Economic Area (EEA).</p>
          <p>We do not use the information you provide to make any automated decisions that might affect you.</p>
          <p>Your personal data is stored for two years, or for as long as we are marketing the product or services that the review refers to,
          whichever is shorter. You may ask us to remove a testimonial that is based on your feedback from our website at any time.</p>
          <h3 id="request-more-information"><strong>When you request more information about our third party products and services</strong></h3>
          <p>When you complete the form to request further information about third party products and services listed on our website, we ask for
          your name, title, company name, company website, work email address, phone number and country.</p>
          <p>We will forward this information as an email to the requested third party based on your consent, which you may withdraw at any time.
          We take your provision of the information and the pressing of the Submit button as an indication of your consent. You will then receive
          relevant communication from the selected third party regarding their products and services.</p>
          <p>Your personal data are stored on our website which is hosted with <a href="https://aws.amazon.com/privacy/"><strong>Amazon Web
          Services</strong></a> based within the United Kingdom.</p>
          <p>We do not use the information you provide to make any automated decisions that might affect you.</p>
          <p>We keep your personal data for two years after you submit the request, after which it will be deleted.</p>
          <h3 id="rights-data-subject">Your rights as a data subject</h3>
          <p>By law, you can ask us what information we hold about you, and you can ask us to correct it if it is inaccurate. If we have asked
          for your consent to process your personal data, you may withdraw that consent at any time.</p>
          <p>If we are processing your personal data for reasons of consent or to fulfill a contract, you can ask us to give you a copy of the
          information in a machine-readable format so that you can transfer it to another provider.</p>
          <p>If we are processing your personal data for reasons of consent or legitimate interest, you can request that your data be erased.</p>
          <p>You have the right to ask us to stop using your information for a period of time if you believe we are not doing so lawfully.</p>
          <p>Finally, in some circumstances you can ask us not to reach decisions affecting you using automated processing or profiling.</p>
          <p>To submit a request regarding your personal data by email, post or telephone, please use the contact information provided above
          in the Who Are We section of this policy.</p>
          <h3 id="right-to-complain">Your right to complain</h3>
          <p>If you have a complaint about our use of your information, we would prefer you to contact us directly in the first instance so that
          we can address your complaint. However, you can also contact the Information Commissioner’s Office via their website at
          <a href="http://www.ico.org.uk/concerns"><strong>www.ico.org.uk/concerns</strong></a> or write to them at:</p>
          <p>Information Commissioner’s Office</p>
          <p>Wycliffe House</p>
          <p>Water Lane</p>
          <p>Wilmslow</p>
          <p>Cheshire</p>
          <p>SK9 5AF</p>
          <h3 id="updates-to-this-policy"><strong>Updates to this privacy policy</strong></h3>
          <p>We regularly review and, if appropriate, update this privacy policy from time to time, and as our services and use of personal data
          evolves. If we want to make use of your personal data in a way that we haven't previously identified, we will contact you to provide
          information about this and, if necessary, to ask for your consent.</p>
          <p>We will update the version number and date of this document each time it is changed.</p>
          <br/>
        </div>
    </section>
  );
}

export default WelcomeNews;
