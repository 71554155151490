import React from 'react';
const tagManagerArgs = {
    dataLayer: {
        userId: '001',
        userProject: 'ExecutiveFunctionSkills',
        page: 'author.js'
    },
    dataLayerName: 'PageDataLayer'
}

function Author() {
      return (
        <section className="text-gray-600 body-font">
          <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
              <div className="ml-8 lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                  <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                      <em>Meet the author</em>
                  </h1>
                  <p className="leading-relaxed">
                      Most don't know Executive Function skills reside in our head and are free and easy to manage.<em> The sooner you take care of them, the better you will feel.</em>
                  </p>
                  <p className="leading-relaxed">
                      David Charles will answer all your questions and you'll discover how easy it is for you and your teens to find a better future and lead a more fulfilling life...
                  </p>

              </div>
              <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                  <a href='./About'>
                    <img className="object-cover object-center rounded h-80" src='https://s3.eu-west-2.amazonaws.com/davidwhiteconsulting.com/images/DW+ClutchingAlpha.png' alt='david charles'/>
                  </a>
              </div>
          </div>
        </section>
      )
}

export default Author;
