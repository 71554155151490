import './App.css';
import React, { useState,useEffect  } from 'react';
import { API, graphqlOperation, Auth} from 'aws-amplify'
import { withAuthenticator } from '@aws-amplify/ui-react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import '@aws-amplify/ui-react/styles.css';
import Downloads from './pages/downloads.js';
import Author from './pages/author.js';
import Header from './pages/header.js';
import Footer from './pages/footer.js';
import Cookies from './pages/cookies'
import Privacy from './pages/privacy'

export const isAuthenticated = async () => {
    try {
        return await Auth.currentSession().isValid()
    } catch (error) {
        return false;
    }
};

function App() {
  const [error, setError] = useState(null);
  const [username, setUsername] = useState(null);

  const showlog = 8;

  useEffect(() => {
    onAppLoad()
    try {
      setError(null);
       Auth.currentAuthenticatedUser({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      }).then(user => {
        if (showlog === 6) console.log('currentAuthenticatedUser username',user.username,'sub',user.attributes.sub, 'email',user.attributes.email)
        setUsername({'username':user.username, 'sub':user.attributes.sub, 'email':user.attributes.email})
      }).catch(err => setError(err));
    }
    catch (e) { setError(e); }
  }, []);

  async function onAppLoad() {
      const user = await Auth.currentAuthenticatedUser();
      if (showlog === 6) console.log('user:', user)
    }

  if (username !== null ) {
    return (
      <body>
      <Header/>
      <Router>
        <Routes>
          <Route path='/' element={<><Author/><Downloads/></>} />
          <Route path='/About' element={<Author/>} />
          <Route path='/privacy.html' element={<Privacy/>} />
          <Route path='/cookies.html' element={<Cookies/>} />
        </Routes>
      </Router>
      <Footer/>
      </body>
    );
  } else {
    return (
      <div>
      <Header/>
      <section className="text-gray-600 body-font">
          <div className="container mx-auto flex flex-col px-5 py-24 justify-center items-center">
            Checking you in...
          </div>
      </section>
      <Footer/>
      </div>
    )
  }
}

export default withAuthenticator(App)
