import React  from 'react';
import TagManager from 'react-gtm-module';
// import '../App2.css';
const tagManagerArgs = {
    dataLayer: {
        userId: '001',
        userProject: 'ExecutiveFunctionSkills',
        page: 'cookies.js'
    },
    dataLayerName: 'PageDataLayer'
}

function WelcomeNews() {
  TagManager.dataLayer(tagManagerArgs);

  return (
    <section className="text-gray-600 body-font">
        <div className="container mx-auto px-5 py-24 items-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Cookie Policy</h1>
            <p><b>Last updated: January 6th, 2022 version 6</b></p>

            <p>To make this site work properly, we sometimes place small data files called cookies on your device. Most big websites do this too.</p>
            <h2>What are cookies?</h2>
            <p>
                A cookie is a small text file that a website saves on your computer or mobile device when you visit the site. It enables the website to remember your actions and preferences (such as login, language, font size and other display preferences) over a period of time, so you don’t have to keep re-entering them whenever you come back to the site or browse from one page to another.

            </p>

            <p>Also, some videos embedded in our pages use a cookie to anonymously gather statistics on how you got there and what videos you visited.</p>

            <h2>How do we use cookies?</h2>
            <p>We use cookies, pixels, and other similar technologies (collectively, "cookies") to recognize your browser or device, learn more about your interests, provide you with essential features and services, and for additional purposes, including:</p>
            <ul>
                <li>Recognising you when you sign in to use online services. This allows us to provide you with recommendations, display personalised content, and provide other customised features and services.</li>
                <li>Keeping track of your specified preferences. This allows us to honor your likes and dislikes, such as your language and configuration preferences.</li>
                <li>Conducting research and diagnostics to improve our offerings.</li>
                <li>Preventing fraudulent activity.</li>
                <li>Improving security.</li>
                <li>Delivering content, including ads, relevant to your interests.</li>
                <li>Reporting. This allows us to measure and analyse the performance of our site.</li>
            </ul>
            <p>Some cookies are deleted at the end of your browsing session, while others persist between sessions. Essential cookies remain on your device for up to 365 days from your last visit to our site. Other cookies remain on your device for up to 365 days from their last use.</p>
            <h2>Information we collect through cookies</h2>
            <p>Examples of the information we automatically collect through cookies include:</p>
            <ul>
                <li>Network and connection information, such as the Internet protocol (IP) address used to connect your computer or other device to the Internet and information about your Internet service provider</li>
                <li>Computer and device information, such as device, application, or browser type and version, browser plug-in type and version, operating system, or time zone setting</li>
                <li>The location of your device or computer</li>
                <li>Authentication and security credential information</li>
                <li>Content interaction information, such as content downloads, streams, and playback details, including duration and number of simultaneous streams and downloads</li>
                <li>The full Uniform Resource Locators (URL) clickstream to, through, and from our site (including date and time), content you viewed or searched for, page response times, download errors, and page interaction information (such as scrolling, clicks, and mouse-overs)</li>
            </ul>
            <p>Enabling these cookies is not strictly necessary for the website to work but it will provide you with a better browsing experience. You can delete or block these cookies, but if you do that some features of this site may not work as intended.</p>
            <p>Cookie-related information is not used to identify you personally and the pattern data is fully under our control. These cookies are not used for any purpose other than those described here.</p>

            <h2>How to control cookies</h2>
            <p>You can control and/or delete cookies as you wish – for details, see aboutcookies.org. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work.</p>

            <h2>Cookie preferences on this site</h2>
            <p>Our cookies allow you to take advantage of some essential and useful features. Blocking some
            types of cookies may impact your experience of our site. You can change your cookie preferences at any time by clicking Cookie preferences
            in the footer of the site.</p>

            <h2>Browser settings</h2>
            <p>You can manage browser cookies through your browser settings. The 'Help'
            feature on most browsers will tell you how to remove cookies from your device, prevent your browser from accepting new cookies, how to have
            the browser notify you when you receive a new cookie, how to disable cookies, and when cookies will expire. Check the support site for your
            browser to understand privacy settings available to you.<br /> <br /> If you block or reject some of our cookies through your browser’s
            settings, you might not be able to use certain offerings that require you to sign in to an account and some features and services may not
            work. You might also have to manually adjust your preferences or settings every time you visit this site. If your browser includes&nbsp;
            <a href="https://webkit.org/tracking-prevention/" rel="noreferrer" target="_blank">Intelligent Tracking Prevention (ITP)</a>, preferences specific to our
            sites may reset on an ongoing basis. These resets are managed by your browser, not by us.</p>

            <h2>Third party cookies</h2>
            <p>Approved third parties may also set cookies when you interact with our offering. Third parties include
            search engines, providers of measurement and analytics services, social media networks, and advertising companies. These third parties
            use cookies in the process of delivering content, including ads relevant to your interests, to measure the effectiveness of their ads,
            and to perform services on behalf of us.<br /> <br /> Below is a list of the third parties that may set cookies when you use our Offerings.
            You can learn more about how these third parties use information collected through cookies by reviewing the privacy policies on their sites.
            <br /> </p>

          <div className='center'>
            <table cellpadding="1" cellspacing="0" border="1" width="50%">
                <tbody>
                    <tr>
                        <td>Adobe</td>
                        <td>adobe.com</td>
                    </tr>
                    <tr>
                        <td>Google</td>
                        <td>google.com</td>
                    </tr>
                    <tr>
                        <td>LinkedIn</td>
                        <td>linkedin.com</td>
                    </tr>
                    <tr>
                        <td>Twitter</td>
                        <td>twitter.com</td>
                    </tr>
                    <tr>
                        <td>YouTube</td>
                        <td>youtube.com</td>
                    </tr>
                    <tr>
                        <td>Zendesk</td>
                        <td>zendesk.com</td>
                    </tr>
                </tbody>
            </table>
            <br/>
            <br/>
            <br/>
          </div>
        </div>
      </section>

  );
}

export default WelcomeNews;
